import React from 'react';

import styled from 'styled-components';
import { closePopUp, openPopUp } from "../actions/PopUpActions"
import { loadUserData_FromStorage } from "../actions/UserAct"
import { withCookies } from 'react-cookie';
import Alert from './PopUp/Views/Alert'
import { connect } from 'react-redux';
import {GlobalFunctions} from '../GlobalFunctions'
import Input from './components/moduls/Input'
import BackImg from '../design/bg.jpg'
import { up, down, between, only } from 'styled-breakpoints';
import Logo_png from '../design/LongLogo.svg'
import { Button } from '@mui/material';
import { Pallet } from '../constants/Constants';


class Login extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
           isLoaded:false,
           isLoadedALL:false
        }
        this.input=[]
    }

    

    componentDidMount() {
       setTimeout(()=>{
           this.setState({isLoaded:true})
       },500)
    }


    signIN(){
        this.setState({errorMSG:null})
        if(!GlobalFunctions.checkReqvireds(this.input,['Email'])) return

        GlobalFunctions.API_task({
            operation:"loginIn",
            block:'Auth', 
            password:encodeURIComponent(this.input.PASSWORD.value),
            login:encodeURIComponent(this.input.LOGIN.value)
        }).then(res=>{
            if(res== null){
               
                this.setState({errorMSG:"Неверный логин или пароль"})
                return
            }
            this.setState({isLoaded:false,isLoadedALL:true})
            setTimeout(()=>{
            
            const { cookies } = this.props
            cookies.set("login", res.LOGIN, { path: '/' })
            cookies.set("password", res.PASSWORD, { path: '/' })
            cookies.set("id", res.ID, { path: '/' })
           

            this.props.loadUserData_FromStorage()
            },1000)
        }).catch((error)=>{
            
            this.setState({errorMSG:error.message})
        })
    }

    ressetPass(){
        this.props.openPopUp(<div style={{
            width:350
        }}>
            <h4>Восстановление пароля</h4>
            <p className="info-text">Укажите почту, к которой привязан Ваш аккаунт, и мы вышлем данные аккаунта</p>
            <Input Ref={this.input} Dark
            title='E-mail' name='Email'/>
            <Button size='small'  onClick={()=>{
                this.setState({errorMSG:null})
                if(!GlobalFunctions.checkReqvireds([this.input.Email])) return

                GlobalFunctions.API_task({
                    operation:"ressetPass",
                    block:'Auth',
                    LOGIN:encodeURIComponent(this.input.Email.value)
                }).then(res=>{
                    this.props.closePopUp()
                    if(res=="ok")
                        this.props.openPopUp(<Alert text="Восстановленный пароль выслан на почту"/>)
                    
                })
            }} >
            Восстановить </Button>
        </div>)
    }

    render (){
        const {} = this.props.page
        const {isLoaded,isLoadedALL} = this.state
        
        return (
            <BodyBlack isLoadedALL={isLoadedALL}>
                <Page  className="Page Login">
                    <BGimg isLoaded={isLoaded}>
                        <DarkBg />
                    </BGimg>
                    
                    <Img isLoaded={isLoaded} src={Logo_png}/>
                    <div ref={ref => (this.recaptcha = ref)}></div>
                    <LoginView isLoaded={isLoaded} isLoadedALL={isLoadedALL} className="row p-4">
                        <div className="col-lg-4 col-md-3"/>
                        <div className="col-lg-4 col-md-6">
                        
                            <View>
                                
                                    <h5>Вход в приложение</h5>
                                
                                    
                                    <Input Ref={this.input}
                                    Dark
                                        title='Логин'
                                        name='LOGIN'
                                        placeholder=''/>
                                    <Input Ref={this.input}
                                    Dark
                                        title='Пароль'
                                        name='PASSWORD'
                                        type='password'
                                        placeholder=''/>
                                    
                                    <div style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'space-between'
                                    }}>
                                        <Button size='small'  onClick={()=>this.signIN()} >
                                        Вход
                                        </Button>

                                        <div className="linkNewPass" onClick={()=>this.ressetPass()} style={{
                                            fontWeight:'500',
                                            fontSize:13,
                                            letterSpacing: '0.3714100122451782px',
                                            lineHeight: '22.75px',
                                            textTransform: 'uppercase',
                                            cursor:'pointer',
                                            fontVariantCaps: 'normal',
                                            fontFamily:'Roboto, Helvetica, Arial, sans-serif'
                                        }}>
                                        Восстановить пароль
                                        </div>
                                    </div>
                                
                                </View>
                            {this.state.errorMSG!=null?
                                <div className="alert alert-danger mt-3 text-center" role="alert">
                                    {this.state.errorMSG}
                                </div>
                            :null}
                            
                            
                        </div>
                        <div className="col-lg-4 col-md-3"/>
                    </LoginView>
                </Page>
            </BodyBlack>
        )
    }
}

const LoginView = styled.div`
${props=>props.isLoadedALL?`transition:1s;`:`transition:1s ease 1s;`}

${props=>props.isLoaded?`
transform:translateY(0);
opacity:1;
`:`
transform:translateY(50px);
opacity:0;
`}
`

const View = styled.div`
background:${Pallet.Gray};
padding:20px !important;
border-radius:5px;
margin-top:50px;
position:relative;
z-index:10;
`

const BodyBlack = styled.div`
height:100vh;
width:100vw;
${props=>props.isLoadedALL?'background:rgba(0,0,0,0);':'background:rgba(0,0,0,1);'}
`


const Img = styled.img`
display:block;
margin:auto;
width:150px;
margin-top:30px;
z-index:1;
position:relative;

transition:1s;

${props=>props.isLoaded?`
transform:translateY(0);
opacity:1;
`:`
transform:translateY(-50px);
opacity:0;
`}
`


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp: (content) => dispatch(closePopUp(content)),
        openPopUp: (content) => dispatch(openPopUp(content)),
      loadUserData_FromStorage : (content) => dispatch(loadUserData_FromStorage(content)),
    }
}


const mapStateToProps = (store) => {
   
    return {
        page:store.page
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(Login));

const BGimg = styled.div`
background:url('${BackImg}');
height:100vh;
    width:100vw;
    transition:1s;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    position:fixed;
    top:0;
    left:0;


${down('md')}{
    ${props=>props.isLoaded?`
    transform:scale(1);
    opacity:1;
    `:`
    transform:scale(1.5);
    opacity:0;
    `}
}

${up('md')}{
    ${props=>props.isLoaded?`
    transform:scale(1);
    opacity:1;
    `:`
    transform:scale(1.1);
    opacity:0;
    `}
}
`

const Page = styled.div`
    
   
    overflow: hidden;
    height:100vh;
    width:100vw;
    



`

const DarkBg = styled.div`
position:fixed;
background:rgba(0,0,0,0.3);
top:0;
left:0px;
width:100vw;
height:100vh;
`
