import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy ,getSHOP_ITEMS} from '../../../../actions/PageActions';
import Input, { Label } from '../../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';
import Alert from '../../../PopUp/Views/Alert';
import POP_FixedBonus from '../FixedBonuses/POP_FixedBonus';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Styles } from '../../../../constants/Constants';
import YC from '../../../../backend/YC';


const fields = [
  {
    title:'Название',
    name:'TITLE'
  },
  {
    title:'Цена',
    name:'COAST',
    type:'number'
  }
]

class POP_TovarChange extends React.Component {
    constructor(props) {
        super(props)
        this.isUsluga = props.tovarType=='услуга'
        this.state = {
          self:{
            COMPANYS:[],
            ID_SHOP_CATEGORY:this.isUsluga?2:1
          }
        }
        this.input={}
        this.previosValue = null

        
       
        
    }
    componentDidMount(){
      this.getSelf()
    }

    getSelf(){
      const {ID_OBJECT,SHOP_ITEMS,YC} = this.props
      if(ID_OBJECT==null) return

      let self = SHOP_ITEMS.find(i=>i.ID==ID_OBJECT)
 
     
      

      this.setState({self},()=>{
        Object.keys(self).forEach(key=>{
          try{
            this.input[key].value=self[key]
          }catch(e){}
        })
      })

     
    }

   

    Delete(){
      GF.API_task({
        operation:'deleteSome',
        Table:'SHOP_ITEMS',
        ID:this.props.ID_OBJECT
      }).then(data => {
        this.props.getSHOP_ITEMS()
          this.props.closePopUp()
      })
    }

    Update(){ 
      if(!GF.checkReqvireds(this.input)) return

      let reqvest = {
        operation:'createUpdateSome',
        Table:'SHOP_ITEMS',
        ID:this.props.ID_OBJECT,
        data:{}
      }

      Object.keys(this.input).forEach(key=>{
        reqvest.data[key]=this.input[key].value
      })

      if(this.isUsluga){
        reqvest.data.YC_ID = this.state.self.YC_ID
        reqvest.data.COAST=null
        reqvest.data.TITLE=null
        reqvest.data.ID_SHOP_CATEGORY=2
      }

      reqvest.data.COMPANYS =JSON.stringify( this.state.self.COMPANYS.map(com=>com.ID) )

      GF.API_task(reqvest).then(data => {
          this.props.getSHOP_ITEMS()
          this.props.closePopUp()
      })
    }

    
  
    render (){
      const {state,props} = this
        const {isDisabled,self} = this.state
        const {SERVICES_BONUS_TYPES,tovarType,ID_OBJECT,COMPANYS} = this.props
        
        if(props.ID_OBJECT!=null && state.self.ID==null) return <div/>

        return (
          <POP> 
            {this.isUsluga?[
              <h4 className='mb-0'>Настройка предложения</h4>,
              <Label>Данные об услуге загружены из YClients</Label>,
              <br/>
              ]
              :
              <h4>Настройка предложения</h4>
            }
            
            {!this.isUsluga?null:
               <Autocomplete
                disablePortal
                size="small"
                
                className='mt-3 mb-2'
                value={this.state.self==null?null: props.YC.services.find(yc=>yc.id==this.state.self.YC_ID)}
                onChange={(e,value)=>{
                  debugger
                  let self = {
                    YC_ID:value?.id,
                    COMPANYS:value?.companys==null?[]:value.companys.map(com=>{
                      return this.props.COMPANYS.find(comp=>comp.ID == com)
                    }),
                    COAST:value?.price_max,
                    TITLE:value?.title
                  }

                  this.setState({self:{
                    ...state.self,
                    ...self
                  }})

                  Object.keys(self).forEach(key=>{
                    try{
                      this.input[key].value=self[key] || ''
                    }catch(e){}
  
                  })
                }}
                options={props.YC.services}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField style={{
                  color:'white'
                }} color='warning' size='small' variant='filled' {...params} label="Услуга" />}
              />  
            }
            
            {fields.map(f=>
                <Input Ref={this.input} {...f} disabled={this.isUsluga?true:false}/>
              )
            }

            <Label>Доступность в филиалах</Label>
            <div style={{
              display:'flex',
              flexWrap:'wrap',
              gap:10
            }}>
            {COMPANYS.map(com=>
                <small
                onClick={()=>{
                  if(this.isUsluga) return
                  let selected_tmp = self.COMPANYS.splice(0)
                  let index = selected_tmp.findIndex(s=>s.ID==com.ID)
                  if(index ==-1)//если не выбран
                    selected_tmp.push(com)
                  else
                    selected_tmp.splice(index,1)
                  
                  this.setState({self:{...self,COMPANYS:selected_tmp}})
                }}
                className={`me-1 mt-1 ${self.COMPANYS.find(c=>c.ID==com.ID)==null?'bg-danger':'bg-success'} p-1 rounded text-light d-block`}>{com.TITLE.replace('Rich Nails ','')}</small>  
            )}
            </div>

            <br/>
            <br/>

            <div style={Styles.inline}>
              {ID_OBJECT==null?null:
                <Button size='small' color='error' onClick={()=>this.Delete()}>Удалить</Button>
              }

          
                <Button size='small' onClick={()=>this.Update()}>Сохранить</Button>
              
            </div>

            
          </POP>
        )
    }
  }

  const POP = styled.div`
  width:350px;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getSHOP_ITEMS:(arg)=>dispatch(getSHOP_ITEMS(arg)),
        
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      SERVICES_BONUS_TYPES:store.page.SERVICES_BONUS_TYPES,
      SHOP_ITEMS:store.page.SHOP_ITEMS,
      YC:store.page.YC,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_TovarChange)