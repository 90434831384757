import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp, closePopUp } from '../../../actions/PopUpActions';
import { getDB_Data_lazy, getVARS, getDB_Data } from '../../../actions/PageActions';
import Input, { Label } from '../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import Alert from '../../PopUp/Views/Alert';
import POP_UseBonusCoins from './POP_UseBonusCoins';
import { Button, IconButton, LinearProgress, Menu, MenuItem, TextField } from '@mui/material';
import POP_NewBonusLog from './POP_NewBonusLog';
import { Pallet } from '../../../constants/Constants';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import 'moment/locale/ru';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Question from '../../PopUp/Views/Question';
import POP_ChangeBonusLog from './POP_ChangeBonusLog';

const fields = [
  {
    title: 'YC_ID',
    key: 'YC_ID',
  },
  {
    title: 'ФИО',
    key: 'FIO',
  },
  {
    title: 'Телефон',
    key: 'PHONE',
  },
  {
    title: 'E-mail',
    key: 'EMAIL',
  },

  {
    title: 'Бонусный счет',
    key: 'BONUS_COINS',
  },
  {
    title: 'Всего получено бонусов',
    key: 'GLOBAL_COINS',
  },
]

const privateFields = [
  {
    title: 'Логин',
    key: 'LOGIN',
  },
]


class POP_Clients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      self: {},
      BONUS_LOG: [],
      nowLoading: true,
      anchorEl: null
    }

    this.prevEmail = null
    this.input = []

  }
  componentDidMount() {
    if (this.props.ID_OBJECT != null)
      this.getSelf()
  }

  getSelf() {

    let self = { ...{ ...this.props.CLIENTS.find(cl => cl.ID == this.props.ID_OBJECT) } }
    this.prevEmail = self.EMAIL
    this.setState({ self })

    setTimeout(() => {
      this.getBonusLog()
    }, 300)


  }

  getBonusLog() {
    GF.API_task({
      operation: 'getBonusLog',
      ID_CLIENT: this.state.self.ID
    }).then(data => {
      data.forEach((d, i) => {
        data[i].TYPE_TITLE = this.props.BONUS_LOG_TYPES.find(b => b.TYPE == d.TYPE).DESCR
      })
      this.setState({ BONUS_LOG: data, nowLoading: false })
    })
  }

  resendPassword() {
    GF.API_task({
      operation: 'resendPassword',
      ID_USER: this.state.self.ID_USER
    }).then(data => {
      this.props.openPopUp(<Alert text={`Письмо отправлено на адрес ${this.state.self.EMAIL}`} />)
    })
  }



  UseBonusCoins(coins, date) {

    GF.API_task({
      operation: 'UseBonusCoins',
      COINS: coins,
      ID_CLIENT: this.state.self.ID,
      ID_COMPANY: this.props.selectedFilial,
      date: date == null ? null : date.format('YYYY-MM-DD')
    }).then(() => {
      if (this.props.user.ROLE == 'MNGR') {
        this.props.openPopUp(<div>
          <p>Клиенту на почту {this.state.self.EMAIL} отправлен код подтверждения</p>
          <Input title='Код подтверждения'
            name='GUID'
            Ref={this.input} />

          <Button size='small' onClick={() => {
            GF.API_task({
              operation: 'UseBonusCoins_complite',
              GUID: this.input.GUID.value,
              ID_COMPANY: this.props.selectedFilial
            }).then((res) => {


              this.props.getDB_Data('getCLIENTS', 'CLIENTS', null, () => {
                setTimeout(() => {
                  this.props.closePopUp()
                  this.props.openPopUp(<Alert text={`Баллы списаны`} />)
                  this.getSelf()
                }, 100)
              })

            }).catch(() => {
              this.props.openPopUp(<Alert text={`Неверный код`} />)
            })
          }}>Дальше</Button>

        </div>)
      } else {

        this.props.getDB_Data('getCLIENTS', 'CLIENTS', null, () => {
          setTimeout(() => {
            this.props.closePopUp()
            this.props.openPopUp(<Alert text={`Баллы списаны`} />)
            this.getSelf()
          }, 100)
        })
      }




    })



  }

  SaveAdmin() {
    //сохранение EMAIL

    if (this.state.self.EMAIL != this.prevEmail) {
      if (this.prevEmail != null && this.prevEmail != '' && (
        this.state.self.EMAIL == ''
      )) {
        this.props.openPopUp(<Alert text={`Нельзя удалить E-mail из карточки клиента`} />)
        return
      }

      if (this.state.self.EMAIL.length < 4 || this.state.self.EMAIL.indexOf('@') == -1 || this.state.self.EMAIL.indexOf('.') == -1) {
        this.props.openPopUp(<Alert text={`Поле E-mail заполнено некорректно`} />)
        return
      }


      GF.API_task({
        operation: 'changeClientEmail',
        ID_CLIENT: this.state.self.ID,
        ID_USER: this.state.self.ID_USER,
        EMAIL: this.state.self.EMAIL
      }).then(() => {
        this.props.getDB_Data('getCLIENTS', 'CLIENTS')
        this.props.openPopUp(<Alert text={'Поле E-mail успешно обновлено в YClients'} />)

        setTimeout(() => this.componentDidMount(), 700)
      }).catch(e => {
        this.props.openPopUp(<Alert text={'Ошибка при обновлении карточки клиента в YClients'} />)
      })
    }





    if (this.props.user.ROLE == 'ADMN') {
      if (
        this.state.self.LOGIN != null && this.state.self.LOGIN != ''
        &&
        (
          this.state.self.LOGIN == null ||
          this.state.self.LOGIN.length == 0 ||
          this.state.self.LOGIN.indexOf('@') == -1
        )) {
        this.props.openPopUp(<Alert text={`Неверно заполнено поле Логин`} />)
        return
      }

      if (this.state.self.LOGIN != null && this.state.self.LOGIN.indexOf('@') == -1) {
        this.props.openPopUp(<Alert text={`Неверно заполнено поле Логин`} />)
        return
      }

      let data = { ...this.state.self }



      GF.API_task({
        operation: 'saveClientInfo',
        ID_CLIENT: this.state.self.ID,
        ID_USER: this.state.self.ID_USER,
        data
      }).then(() => {
        this.props.getDB_Data('getCLIENTS', 'CLIENTS')
        this.props.closePopUp()
      })

    }
  }

  getBonusLogYC(bl, obj) {
    const { SELECTED_SERVICES, SHOP_ITEMS, YC } = this.props
    let result = []


    if (bl.ID_SERVICE != null && bl.TYPE == 'U') {

      result.push(<p className='m-0 d-inline pointer' onClick={() =>
        window.open(`https://yclients.com/settings/services_edit/basic/177644/7773718/${bl.YC_ID}`)
      }>Услуга в  YC <strong>{bl.YC_ID}</strong> </p>)
    }

    if (bl.ID_SERVICE != null && bl.TYPE == 'D') {

      result.push(<p className='m-0 d-inline pointer' onClick={() =>
        window.open(`https://yclients.com/settings/services_edit/basic/177644/7773718/${bl.YC_ID}`)
      }>Услуга в  YC <strong>{bl.YC_ID}</strong></p>)
    }


    return result
  }

  deleteBinusLogItem = () => {
    this.props.openPopUp(<Question
      text='Вы уверены, что хотите удалить запись из лога начисления баллов?'
      buttons={<>
        <Button color='error' onClick={() => {
          this.setState({ nowLoading: true })
          GF.API_task({
            operation: 'deleteBonusLogItem',
            ID_LOG: this.state.openListElem.ID
          }).then(() => {
            this.props.getDB_Data('getCLIENTS', 'CLIENTS', null, () => {
              this.getSelf()
            })
          })
          this.props.closePopUp()
        }}>Да, удалить</Button>
        <Button color='warning' onClick={() => this.props.closePopUp()}>Отмена</Button>
      </>}
    />)
  }

  openBinusLogItem = () => {
    this.props.openPopUp(<POP_ChangeBonusLog SelfData={this.state.openListElem} callBack={() => this.getSelf()} />)
  }

  render() {
    const { self, BONUS_LOG, nowLoading } = this.state
    const { SELECTED_SERVICES, SHOP_ITEMS, YC, user } = this.props


    if (this.state.self.ID == null && this.props.ID_OBJECT != null) return <div />

    return (
      <POP>

        <div className='row'>
          <div className='col-5'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4 className='m-0'>Клиент</h4> <b className='m-0' style={{ color: 'white' }}>Уровень <b>{this.state.self.LVL}</b></b>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className='m-0'>Кешбэк <b>{GF.makeCoast(this.state.self.CASHBACK_SUZE * 100)}%</b></p>
              <p className='m-0'>Скидка <b>{GF.makeCoast(this.state.self.DISCOUNT_SIZE * 100)}%</b></p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className='m-0'>Потрачено средств</p>
              <p className='m-0'><b>{GF.makeCoast(this.state.self.ALL_COAST)}руб</b></p>
            </div>
            {fields.map(f =>
              user.ROLE != 'ADMN' && f.key != 'EMAIL' ? [
                <Label>{f.title}</Label>,
                <p>{self[f.key]}</p>
              ] :
                <TextField style={{
                  color: 'white'
                }}
                  size="small"
                  className='mb-2 w-100'
                  disabled={f.key == 'YC_ID'}
                  color='warning'
                  variant='filled'
                  value={this.state.self[f.key]}
                  label={f.title}
                  onChange={(e) => {
                    let tmp = { ...{ ...this.state.S = self } }
                    tmp[f.key] = e.target.value
                    this.setState({
                      self: tmp
                    })
                  }} />

            )}
            {user.ROLE != 'ADMN' ? null : [
              <hr />,
              privateFields.map(f =>
                <TextField style={{
                  color: 'white'
                }}
                  size="small"
                  color='warning'
                  className='mb-2 w-100'
                  variant='filled'
                  value={this.state.self[f.key]}
                  label={f.title}
                  onChange={(e) => {
                    let tmp = { ...{ ...this.state.S = self } }
                    tmp[f.key] = e.target.value
                    this.setState({
                      self: tmp
                    })
                  }} />
              )
            ]
            }

          </div>

          <div className='col-7'>
            <h5>История
              <Button className='ms-2' size='small' onClick={() => {
                this.props.openPopUp(<POP_NewBonusLog user={self} callBack={() => this.getSelf()} />)
              }}>Добавить запись</Button>
            </h5>

            <ScrollView>
              {nowLoading ?
                <LinearProgress color='warning' /> :
                BONUS_LOG.length == 0 ?
                  <small>Действий не обнаружено</small>
                  : (() => {
                    var companyTitle = ''
                    var BONUS_LOG_grouped = {}
                    BONUS_LOG.forEach((bl, index) => {
                      if (bl.YC_ORDER_ID == null) bl.YC_ORDER_ID = 'от ' + moment(bl.DATE_CREATE).locale('ru').format('D MMMM YYYY')

                      if (Object.keys(BONUS_LOG_grouped).findIndex(key => key == 'order_' + bl.YC_ORDER_ID) == -1)
                        BONUS_LOG_grouped['order_' + bl.YC_ORDER_ID] = []

                      BONUS_LOG_grouped['order_' + bl.YC_ORDER_ID].push(bl)

                    })

                    var resultView = []
                    Object.keys(BONUS_LOG_grouped).forEach(key => {
                      let order = key.replace('order_', '') || null
                      companyTitle = null

                      if (order != null && order.indexOf(' ') == -1) order = order * 1

                      BONUS_LOG_grouped[key].forEach(bl => {
                        if (bl.COMPANY_TITLE != null) {
                          companyTitle = bl.COMPANY_TITLE?.replace('Rich Nails ', '')
                        }
                      })


                      var resultViewTMP = []
                      BONUS_LOG_grouped[key].forEach(bl => {


                        let obj = null
                        if (bl.TYPE == 'U') {
                          obj = {
                            TITLE: bl.YC_TITLE,
                            VALUE: bl.YC_COAST,
                            YC_ID: bl.YC_ID
                          }

                        } else {
                          try {

                            obj = SHOP_ITEMS.find(si => si.ID == bl.ID_SERVICE)

                            obj.VALUE = bl.VALUE
                            obj.YC_ID = obj.YC_ID
                          } catch (r) {

                            obj = null
                          }
                        }

                        if (obj == null)

                          obj = {
                            TITLE: '',
                            VALUE: bl.VALUE,
                          }

                        if (bl.TYPE == 'M') {
                          obj = {
                            TITLE: 'Счет баллов',
                            VALUE: (bl.VALUE > 0 ? '+' : '') + bl.VALUE
                          }
                        }
                        if (bl.TYPE == 'G') {
                          obj = {
                            TITLE: 'Общий рейтинг',
                            VALUE: (bl.VALUE > 0 ? '+' : '') + bl.VALUE
                          }
                        }
                        if (bl.TYPE == 'E') {
                          obj = {
                            TITLE: 'Изменены данные клиента',
                            VALUE: null
                          }
                        }




                        resultViewTMP.push(
                          <BalLog>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}>
                              <div>
                                <Label>{moment(bl.DATE_CREATE).local('ru').format('D MMM YYYY')}
                                  {bl.COMPANY_TITLE != null && (order + '').indexOf('от') != -1 ?
                                    ' - ' + bl.COMPANY_TITLE?.replace('Rich Nails ', '') : null
                                  }
                                </Label>
                                <Label>{bl.TYPE_TITLE}</Label>
                                <Label style={{ color: 'rgb(112, 32, 20)' }}>
                                  {this.getBonusLogYC(bl, obj)}
                                </Label>
                                <p>{obj.TITLE}</p>
                                <b style={{ color: Pallet.Primary.Accent }}>{GF.makeCoast(obj.VALUE)}</b>
                              </div>
                              {this.props.user.ROLE != 'ADMN' || bl.TYPE == 'E' || bl.TYPE == 'L' ? null :
                                <div>
                                  <IconButton onClick={(e) => {
                                    this.setState({ anchorEl: e.currentTarget, openListElem: { ...bl } })
                                  }}>
                                    <MoreVertIcon fontSize='small' />
                                  </IconButton>
                                </div>
                              }
                            </div>
                          </BalLog>
                        )
                      })

                      resultView.push(
                        <div style={{
                          backgroundColor: Pallet.Light.White,
                          border: '1px solid ' + Pallet.Light.White,
                          borderRadius: 5,
                          padding: 10,
                          marginBottom: 10,
                          overflow: 'hidden'
                        }}>

                          {order + '' == "NaN" || order == null ? null :
                            <>
                              <div style={{
                                display: 'flex',
                                justifyContent: "space-between",
                                alignContent: 'center',
                                alignItems: "center",
                                marginLeft: -15,
                                marginTop: -15
                              }}>


                                <p className='m-0' style={{
                                  paddingLeft: 15,
                                  paddingTop: 5,
                                  paddingBottom: 5
                                }}>
                                  {(order + '').indexOf('от') == -1 ? <b style={{
                                    marginRight: 10
                                  }}>Заказ</b> : null}

                                  <a href={
                                    (order + '').indexOf(' ') == -1 ?
                                      `https://yclients.com/timetable/177644/#main_date=${GF.makeNormalDate(new Date(), 'yyyy-mm-dd')}&open_modal_by_record_id=${order}`
                                      : null
                                  } target='blank' style={{
                                    textDecoration: "none",
                                    color: Pallet.Primary.Accent
                                  }}>{order}</a></p>


                                {(order + '').indexOf(' ') == -1 ?
                                  <small>{companyTitle}</small> : null}
                              </div>
                              <hr style={{
                                marginBottom: 10,
                                marginTop: 0
                              }} />
                            </>
                          }

                          {resultViewTMP}
                        </div>
                      )
                    })

                    return (resultView)
                  })()}
            </ScrollView>

            <Menu
              anchorEl={this.state.anchorEl}
              open={Boolean(this.state.anchorEl)}
              onClose={() => {
                this.setState({ anchorEl: null })
              }}
            >
              <MenuItem key='m1'
                onClick={() => {
                  this.setState({ anchorEl: null })
                  this.openBinusLogItem()
                }}>
                <CreateIcon fontSize='small' sx={{ mr: 2 }} /> Изменить
              </MenuItem>
              <MenuItem key='m2'
                onClick={() => {
                  this.setState({ anchorEl: null })
                  this.deleteBinusLogItem()
                }}>
                <DeleteForeverIcon fontSize='small' sx={{ mr: 2 }} /> Удалить
              </MenuItem>
            </Menu>


          </div>
        </div>

        <div style={{
          display: 'flex',

          justifyContent: "space-between",
        }}>
          {user.ROLE != 'ADMN' && user.ROLE != 'MNGR' ? null : [
            <Button size='small' onClick={() => {
              this.SaveAdmin()
            }}>Сохранить</Button>,

          ]}
          <Button size='small' color='info' onClick={() => {
            this.resendPassword()
          }}>Выслать пароль</Button>

          {self.BONUS_COINS == 0 ? null :
            <Button size='small' onClick={() =>
              this.props.openPopUp(<POP_UseBonusCoins currentUser={self} currentCount={self.BONUS_COINS} callBack={(count, date) => {
                this.UseBonusCoins(count, date)
              }} />)
            }>
              Списать баллы
            </Button>
          }
          {self.BONUS_COINS == 0 || user.ROLE != 'ADMN' ? null :
            <Button size='small' onClick={() =>
              this.props.openPopUp(<POP_UseBonusCoins manual currentCount={self.BONUS_COINS} callBack={(count, date) => {
                this.UseBonusCoins(count, date)
              }} />)
            }>
              Ручное списание
            </Button>
          }
        </div>


      </POP>
    )
  }
}

const POP = styled.div`
  width:670px;

  & .pointer{
    cursor:pointer;
    opacity:0.8;
  }
  & .pointer:hover{
    opacity:1;
  }
  `

const ScrollView = styled.div`
  height:500px;
  max-height:500px;
  overflow-y:auto;
  margin-bottom:20px;
  `

const BalLog = styled.div`
  
  & p{
    font-size:13px;
    line-height:14px;
  }
  & b{
    margin-top: -15px;
margin-bottom: 20px;
display: block;
  }
  `





const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getDB_Data_lazy: (arg, q, a, z) => dispatch(getDB_Data_lazy(arg, q, a, z)),
    getDB_Data: (arg, q, a, z) => dispatch(getDB_Data(arg, q, a, z)),
  }
}


const mapStateToProps = (store) => {

  return {
    selectedFilial: store.page.selectedFilial,
    SELECTED_SERVICES: store.page.SELECTED_SERVICES,
    CLIENTS: store.page.CLIENTS,
    SHOP_ITEMS: store.page.SHOP_ITEMS,
    YC: store.page.YC,
    user: store.user.data,
    BONUS_LOG_TYPES: store.page.BONUS_LOG_TYPES,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_Clients)