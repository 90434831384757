import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../actions/PopUpActions';
import {  getDB_Data_lazy,getWidgets } from '../../../actions/PageActions';
import Input, { Label } from '../../components/moduls/Input'
import { GlobalFunctions as GF } from '../../../GlobalFunctions';
import DataWindow from '../Settings/Services/DataWindow';
import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';



class POP_widget extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          isCopyed:false,
          YC_masters:[],
          YC_filials:[],
          master:null,
          loading:true,
          services:[],
          categoris:[],
          filials:[]
        }
        
    }
    async componentDidMount(){
      let YC_masters = []
      let YC_filials = []
      await GF.API_task({
        operation:'getMasters'
      }).then(data=>{
        YC_filials=data
        for(let i=0;i<1;i++){ // количество филиалов - ToDo переделать в нормальное определение!
          
            console.log(i);
            console.log(data[i].data);
            
          data[i].data = data[i].data.map(m=>(
            {...m,
              company_id:data[i].id,
              company_title:data[i].title
            }
          ))
          YC_masters=[...YC_masters,...data[i].data]
        }

        
      })
      
      this.setState({loading:false,YC_masters,YC_filials})
    }

   
  
    render (){
      const {props,state} = this
      const URL = 'https://sinails.quicksearch.futuris.shop/?'
      let QV = URL

      if(state.services.length>0){
        QV+='services='+JSON.stringify(state.services.map(d=>d.id))+'&'
      }
      if(state.categoris.length>0){
        QV+='categoris='+JSON.stringify(state.categoris.map(d=>d.id))+'&'
      }
      if(state.filials.length>0){
        QV+='filials='+JSON.stringify(state.filials.map(d=>d.id))+'&'
      }
      if(state.master!=null){
        QV+='master='+state.master.id
      }

      if(QV.at(-1)=='&' || QV.at(-1)=='?')
        QV=QV.substring(0,QV.length-1)

     // <Autocomplete
            //   disablePortal
            //   size="small"
            //   className='mt-3 mb-2'
            //   value={state.filials}
            //   multiple
            //   onChange={(e,value)=>this.setState({filials:value})}
            //   options={state.YC_filials}
            //   getOptionLabel={(option) => option.title}
              
            //   renderInput={(params) => <TextField style={{
            //     color:'white'
            //   }} color='warning' size='small' variant='filled' {...params} label="Филиал" />}
            // />  
        
        return (
          <POP> 
            <h4>Конструктор URL быстрого виджета</h4>
            <p>Настройте фильтрацию услуг</p>
            {state.loading?<small>Получение данных из YC...</small>:<>


            


            <Autocomplete
              disablePortal
              size="small"
              className='mt-3 mb-2'
              value={state.master}
              onChange={(e,value)=>this.setState({master:value})}
              options={state.YC_masters}
              getOptionLabel={(option) => option.name+" - "+option.company_title}
              
              renderInput={(params) => <TextField style={{
                color:'white'
              }} color='warning' size='small' variant='filled' {...params} label="Мастер" />}
            />  

            <Autocomplete
              disablePortal
              size="small"
              className='mt-3 mb-2'
              value={state.categoris}
              multiple
              onChange={(e,value)=>this.setState({categoris:value})}
              options={props.YC.categoris}
              getOptionLabel={(option) => option.title}
              
              renderInput={(params) => <TextField style={{
                color:'white'
              }} color='warning' size='small' variant='filled' {...params} label="Категории" />}
            />  

            <Autocomplete
              disablePortal
              size="small"
              className='mt-3 mb-2'
              value={state.services}
              onChange={(e,value)=>this.setState({services:value})}
              options={props.YC.services}
              getOptionLabel={(option) => option.title}
              multiple
              renderInput={(params) => <TextField style={{
                color:'white'
              }} color='warning' size='small' variant='filled' {...params} label="Услуги" />}
            />  

            
              
            </>}


            <small className='mt-3 d-block'>Ссылка на быстрый виджет с примененными фильтрами</small>
            
            <a style={{
              fontSize:14,
              display:'block',
              marginBottom:16
            }} href={QV} target='blank'>{QV}</a>

            <Button color='warning' onClick={()=>{
              if(state.isCopyed) return
              this.setState({isCopyed:true})
              setTimeout(()=>{
                this.setState({isCopyed:false})
              },1000)
              navigator.clipboard.writeText(QV)
            }}>{state.isCopyed?'Скопировано!':'Скопировать ссылку'}</Button>

          </POP>
        )
    }
  }

  const POP = styled.div`
  width:500px;
  `


 


const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
        getWidgets:(arg)=>dispatch(getWidgets(arg)),
    }
  }


const mapStateToProps = (store) => {
   
    return {
      SELECTED_SERVICES:store.page.SELECTED_SERVICES,
      COMPANYS:store.page.COMPANYS,
      WIDGETS:store.page.WIDGETS,
      YC:store.page.YC,
      VARS:store.page.VARS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(POP_widget)