import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp, closePopUp } from '../../../../actions/PopUpActions';
import { getDB_Data_lazy, getDB_Data, getBONUS_LOG } from '../../../../actions/PageActions';
import { GlobalFunctions as GF } from '../../../../GlobalFunctions';

import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Table from '../../../components/Table';
import { PaginationCont } from '../../Clients';
import POP_Client from '../../Clients/POP_Client';
import Slider from '@mui/material/Slider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Autocomplete, Button, Alert, AlertTitle, LinearProgress, CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import POP_SearchClients from './POP_SearchClients';
import { Pallet } from '../../../../constants/Constants';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Question from '../../../PopUp/Views/Question';
import POP_ChangeBonusLog from '../../Clients/POP_ChangeBonusLog';

const Rows = [

  {
    title: 'ID',
    key: 'ID',
    width: '80px',
    type: 'int',
  },
  {
    title: 'Тип',
    key: 'TYPE_DESCR',
    type: 'int',
  },

  {
    title: 'Значение',
    key: 'VALUE',
    width: '120px',
    type: 'int',
  },
  {
    title: 'Клиент',
    key: 'CLIENT',
    width: '160px',
    sortKey: 'CLIENT.FIO',
    notSorting: true,
    type: 'string',
  },
  {
    title: 'YClients',
    key: 'YClients',
    width: '300px',
    notSorting: true,
    type: 'string',
  },
  {
    title: 'Филиал',
    key: 'COMPANY_TITLE',
    width: '100px',
    notSorting: true,
    type: 'string',
  },
  {
    title: 'Дата время',
    key: 'DATE_CREATE',
    notSorting: true,
    width: '200px',
    type: 'date',
  },
]

const nullTimeStart = { 'hour': 0, 'minute': 0, 'second': 0 }
const nullTimeEnd = { 'hour': 23, 'minute': 59, 'second': 59 }

class Index extends React.Component {
  constructor(props) {
    super()

    let dateStart = moment()
    dateStart.set(nullTimeStart)

    let dateEnd = moment()
    dateEnd.add(1, "D").set(nullTimeEnd)

    this.state = {
      anchorEl: null,
      nowLoading: false,
      openListElem: null,
      errorMSG: null,
      succes: false,
      filter: null,
      orderKey: 'ID',
      orderDesc: false,
      pop_filter: null,
      Data: [],
      page: 1,
      filters: {
        ballsGeted: [0, 1000],
        dateStart,
        dateEnd,
        clients: []
      }
    }
    this.input = {
      dataWindow: {},
      main: {}
    }
    this.checkBoxs = {}

    this.interval = null
  }

  componentDidMount() {
    this.getLog()
    this.interval = setInterval(() => {
      this.getLog()
    }, 60000)
  }

  getLog() {
    if (this.state.nowLoading) return

    this.setState({ nowLoading: true })

    const dateFilters = {
      DATE_START: this.state.filters.dateStart,
      DATE_END: this.state.filters.dateEnd
    }
    this.props.getBONUS_LOG(dateFilters, () => {
      this.setState({ nowLoading: false })
    })
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  bonysType(type, value) {
    switch (type) {
      case 'FIX': return `${value}Б`
      case 'IND': return `${value}%`


      default: return `${this.props.VARS[type] != null ? this.props.VARS[type].value : value}%`
    }
  }

  deleteBinusLogItem = () => {
    this.props.openPopUp(<Question
      text='Вы уверены, что хотите удалить запись из лога начисления баллов?'
      buttons={<>
        <Button color='error' onClick={() => {
          this.setState({ nowLoading: true })
          GF.API_task({
            operation: 'deleteBonusLogItem',
            ID_LOG: this.state.openListElem.ID
          }).then(() => {
            this.props.getDB_Data('getCLIENTS', 'CLIENTS', null, () => {
              this.getLog()
            })
          })
          this.props.closePopUp()
        }}>Да, удалить</Button>
        <Button color='warning' onClick={() => this.props.closePopUp()}>Отмена</Button>
      </>}
    />)
  }

  openBinusLogItem = () => {
    this.props.openPopUp(<POP_ChangeBonusLog SelfData={this.state.openListElem} callBack={() => this.getLog()} />)
  }

  getServTitle(data) {
    const { filter, Data } = this.state
    const { YC } = this.props
    if (YC.services.length == 0)
      return null

    let serv = YC.services.find(serv => serv.id == data.YC_ID)
    if (serv == null)
      return <p style={{ color: 'red', margin: 0 }}>Объект не найден в YClients</p>

    else
      return [
        serv.title,
        <br />,
        this.props.COMPANYS.length == 0 ? null :
          serv.companys.map(com_id =>
            <small className='me-2 mt-2 bg-light p-1 rounded'>{this.props.COMPANYS.find(c => c.ID == com_id).TITLE}</small>
          )
      ]
  }


  render() {
    const { filter, Data, selectedBonusType, orderKey, orderDesc, page, filters } = this.state
    const { BONUS_LOG, CLIENTS, BONUS_LOG_TYPES, COMPANYS } = this.props

    if (BONUS_LOG == null) return <LinearProgress color='warning' />

    var BONUS_LOG_filered = BONUS_LOG.slice(0)

    BONUS_LOG_filered = BONUS_LOG_filered.filter(d => {

      let DATE_CREATE_TIME = moment(d.DATE_TIME_CREATE)

      if (DATE_CREATE_TIME.isBefore(filters.dateStart))
        return false
      if (DATE_CREATE_TIME.isAfter(filters.dateEnd))
        return false

      if (filters.company != null && d.ID_COMPANY != filters.company)
        return false



      if (filters.typeLog != null && d.TYPE != filters.typeLog)
        return false

      let maxBalls = filters.ballsGeted[1] == 1000 ? 99999999 : filters.ballsGeted[1]

      if (d.VALUE < filters.ballsGeted[0] || d.VALUE > maxBalls)
        return false


      if (filters.clients.length > 0 && filters.clients.findIndex(c => c.ID == d.CLIENT.ID) == -1)
        return false


      return true
    })
    debugger

    let allPageCount = Math.floor(BONUS_LOG_filered.length / 20)
    if (allPageCount == 0)
      allPageCount = 1
    let paginationView = []

    if (allPageCount < page) {
      this.setState({ page: 1 })
    }

    if (page > 8) {
      paginationView.push(
        <p onClick={() => this.setState({ page: 1 })} key={'page_1'} className='small'>1...</p>
      )
    }

    for (let i = 1; i <= allPageCount; i++) {
      if (
        page >= 4 && Math.abs(page - i) <= 3 ||
        page <= 4 && i <= 7 ||
        page >= allPageCount - 4 && i >= allPageCount - 7
      )
        paginationView.push(
          <p key={'page_' + i} className={page == i ? 'selected' : ''} onClick={() => this.setState({ page: i })}>{i}</p>
        )
    }

    if (page < allPageCount - 8) {
      paginationView.push(
        <p onClick={() => this.setState({ page: allPageCount })} key={'page_' + allPageCount} className='small'>...{allPageCount}</p>
      )
    }

    let BONUS_LOG_filered_ALL = BONUS_LOG_filered.slice(0)

    let uniqYC = []
    BONUS_LOG_filered_ALL.forEach(s => {
      if (s.TYPE == 'U' && uniqYC.findIndex(y => y == s.YC_ORDER_ID) == -1)
        uniqYC.push(s.YC_ORDER_ID)
    })

    BONUS_LOG_filered = BONUS_LOG_filered_ALL.sort((a, b) => orderDesc ?
      a[orderKey] > b[orderKey] ? 1 : a[orderKey] < b[orderKey] ? -1 : 0
      :
      b[orderKey] > a[orderKey] ? 1 : b[orderKey] < a[orderKey] ? -1 : 0
    )

    BONUS_LOG_filered = BONUS_LOG_filered.slice((page - 1) * 20, page * 20)




    return (
      <MainView>

        <FiltersBar>
          <Filter>
            <DatePicker
              disabled={this.state.nowLoading}
              maxDate={new Date()}
              label="Дата с"
              value={filters.dateStart}
              onChange={(newValue) => {
                newValue = moment(newValue)
                newValue.set(nullTimeStart)
                if (newValue.isAfter(filters.dateEnd))
                  this.setState({
                    filters: {
                      ...filters,
                      dateStart: newValue,
                      dateEnd: newValue
                    }
                  }, () => this.getLog())
                else
                  this.setState({
                    filters: {
                      ...filters,
                      dateStart: newValue
                    }
                  }, () => this.getLog())

              }}
              renderInput={(params) => <TextField sx={{ width: 150 }} variant='filled' size='small' {...params} />}
            />

          </Filter>
          <Filter>
            <DatePicker
              disabled={this.state.nowLoading}
              label="Дата по"
              maxDate={moment().add(1, 'D').set(nullTimeEnd).format('DD/MM/YYYY')}
              value={filters.dateEnd}
              onChange={(newValue) => {
                newValue = moment(newValue)
                newValue.set(nullTimeEnd)
                if (newValue.isBefore(filters.dateStart))
                  this.setState({
                    filters: {
                      ...filters,
                      dateStart: newValue,
                      dateEnd: newValue
                    }
                  })
                else
                  this.setState({
                    filters: {
                      ...filters,
                      dateEnd: newValue
                    }
                  })
              }}
              renderInput={(params) => <TextField sx={{ width: 150 }} variant='filled' size='small'  {...params} />}
            />
          </Filter>

          <Filter>
            <Label>{filters.clients.length > 0 ? 'Выбрано: ' + filters.clients.length : 'Все клиенты'}</Label>
            <Button color='info' onClick={() => {
              this.props.openPopUp(<POP_SearchClients selected={filters.clients} select={sel => this.setState({
                filters: {
                  ...filters,
                  clients: sel
                }
              })} />)
            }}>
              Поиск по клиентам
            </Button>
          </Filter>

          <Filter>
            <Label>Типы операций</Label>
            <select className='form-control' style={{ width: 150 }}
              value={filters.typeLog}
              onChange={(e) => {
                this.setState({
                  filters: {
                    ...filters,
                    typeLog: e.target.value == -1 ? null : e.target.value
                  }
                })
              }}>
              <option value={-1}>Все операции</option>
              {BONUS_LOG_TYPES.map(s =>
                <option value={s.TYPE}>{s.DESCR}</option>
              )}
            </select>
          </Filter>

          <Filter>
            <Label>Филиал</Label>
            <select className='form-control' style={{ width: 150 }}
              onChange={(e) => {
                this.setState({
                  filters: {
                    ...filters,
                    company: e.target.value == -1 ? null : e.target.value
                  }
                })
              }}>
              <option value={-1}>Все филиалы</option>
              {COMPANYS.map(s =>
                <option value={s.ID}>{s.TITLE}</option>
              )}
            </select>
          </Filter>

          <Filter width={250}>
            {filters.ballsGeted[0] == filters.ballsGeted[1] ?
              <Label>Получено баллов ({filters.ballsGeted[1] == 1000 ? '1000+' : filters.ballsGeted[1]})</Label>
              :
              <Label>Получено баллов ({filters.ballsGeted[0]} - {filters.ballsGeted[1] == 1000 ? '1000+' : filters.ballsGeted[1]})</Label>
            }
            <Slider
              color='warning'
              max={1000}
              valueLabelDisplay="auto"
              value={filters.ballsGeted}
              onChange={(e, value) => {

                this.setState({
                  filters: {
                    ...filters,
                    ballsGeted: value
                  }
                })
              }}
            />
          </Filter>

        </FiltersBar>

        <div style={{
          display: 'flex',
          marginTop: 10,
          marginBottom: -10,
          flexWrap: 'wrap',
          gap: 20
        }}>
          <PaginationCont>
            {paginationView}
          </PaginationCont>

          <div style={{
            minWidth: 890
          }}>
            <Button className='me-2' >
              Записей <strong className='ms-2'>{GF.makeCoast(BONUS_LOG_filered_ALL.length)}</strong>
              {this.state.nowLoading == false ? null : <CircularProgress style={{
                width: 10,
                height: 10,
                marginLeft: 10
              }} color='warning' />}
            </Button>

            <Button className='me-2' >
              Средств потрачено <strong className='ms-2'>{(() => {
                let sumCoast = 0
                BONUS_LOG_filered_ALL.forEach(s => {
                  if (s.TYPE == 'U')
                    sumCoast += s.YC_COAST
                })
                return GF.makeCoast(sumCoast)
              })()}</strong>
            </Button>

            <Button className='me-2' onClick={() => {
              this.setState({
                filters: {
                  ...filters,
                  typeLog: 'U'
                }
              })
            }}>
              Баллов начисленно <strong className='ms-2'>{
                (() => {
                  let sumBall = 0
                  BONUS_LOG_filered_ALL.forEach(s => {
                    if (s.TYPE == 'U' || (s.TYPE == 'M' && s.VALUE > 0) || s.TYPE.length == 2)
                      sumBall += s.VALUE
                  })
                  return GF.makeCoast(sumBall)
                })()
              }</strong>
            </Button>

            <Button className='me-2' onClick={() => {
              this.setState({
                filters: {
                  ...filters,
                  typeLog: 'D'
                }
              })
            }}>
              Баллов списано  <strong className='ms-2'>{(() => {
                let sumBall = 0
                BONUS_LOG_filered_ALL.forEach(s => {
                  if (s.TYPE == 'D' || (s.TYPE == 'M' && s.VALUE <= 0))
                    sumBall += s.VALUE
                })
                return GF.makeCoast(sumBall)
              })()}</strong>
            </Button>

            <Button className='me-2' >
              Кол-во чеков <strong className='ms-2'>{GF.makeCoast(uniqYC.length)}</strong>
            </Button>
          </div>
        </div>

        <Table
          orderKey={orderKey}
          orderDesc={orderDesc}
          className='mt-3'
          rows={Rows}
          canCheck={false}
          checkBoxs={this.checkBoxs}
          data={BONUS_LOG_filered}
          itemOnClick={({ data, index, event }) => {
            this.setState({ anchorEl: event.currentTarget, openListElem: { ...data } })
          }}
          rowOnClick={({ data, index }) => {
            if (data.notSorting === true) return
            if (orderKey == data.key)
              this.setState({ orderDesc: !orderDesc })
            else
              this.setState({ orderKey: data.key, orderDesc: false, sortKey: data.sortKey == null ? data.key : data.sortKey })
          }}
          renderItem={({ data, index }) =>
            Rows.map(r => {
              let current = GF.makeNormalDate(new Date(), 'dd.mm.yyyy')
              let bdTime = new Date(data.DATE_TIME_CREATE)
              bdTime.setHours(bdTime.getHours() + 2)

              let date = GF.makeNormalDate(bdTime, 'dd.mm.yyyy')
              let time = GF.makeNormalDate(bdTime, 'H:M')

              if (current == date)
                date = 'Сегодня'

              switch (r.key) {

                case 'CLIENT':
                  return (<td><Chip style={{
                    background: Pallet.Light.White
                  }} onClick={(e) => {
                    e.stopPropagation()
                    this.props.openPopUp(<POP_Client ID_OBJECT={data.CLIENT.ID} />)
                  }}>
                    {data.CLIENT != null && data.CLIENT.ID != null ? data.CLIENT.FIO.substr(0, 25) : '404'}
                    <br />
                    <small>{data.CLIENT != null && data.CLIENT.PHONE != null ? GF.phoneFormatter(data.CLIENT.PHONE) : ''}</small>
                  </Chip></td>)




                case 'DATE_CREATE':
                  return (<td>{date} {time}</td>)

                case 'YClients':
                  if (data.TYPE == 'U')
                    return (<td>
                      {data.YC_ORDER_ID == null ? null :
                        <p className='m-0'><strong>Чек:</strong><a href={
                          `https://yclients.com/timetable/177644/#main_date=${GF.makeNormalDate(new Date(), 'yyyy-mm-dd')}&open_modal_by_record_id=${data.YC_ORDER_ID}`
                        } target='_blank'> {data.YC_ORDER_ID} </a></p>
                      }
                      {data.YC_ID == null ? null : [
                        <p className='m-0'><strong>Услуга:</strong><a href={
                          `https://yclients.com/settings/services_edit/basic/177644/7773718/${data.YC_ID}`
                        } target='_blank'> {data.YC_ID}</a>
                          <br />{data.YC_TITLE.substr(0, 30)}{data.YC_TITLE.length > 30 ? '...' : ''}</p>,
                        <p className='m-0'><strong>Стоимость:</strong> {GF.makeCoast(data.YC_COAST)} руб</p>
                      ]}
                    </td>)
                  else
                    return (<td> - </td>)
                default: return (<td>{data[r.key]}</td>)
              }

            })
          } />

        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({ anchorEl: null })
          }}
        >
          <MenuItem key='m1'
            onClick={() => {
              this.setState({ anchorEl: null })
              this.openBinusLogItem()
            }}>
            <CreateIcon fontSize='small' sx={{ mr: 2 }} /> Изменить
          </MenuItem>
          <MenuItem key='m2'
            onClick={() => {
              this.setState({ anchorEl: null })
              this.deleteBinusLogItem()
            }}>
            <DeleteForeverIcon fontSize='small' sx={{ mr: 2 }} /> Удалить
          </MenuItem>
        </Menu>

      </MainView>
    )
  }
}

const MainView = styled.div`

`


const FiltersBar = styled.div`
display:flex;
`
const Filter = styled.div`
margin-right:20px;
width:${props => props.width}px;
`

const Chip = styled.div`
background:rgb(240,240,240);
border-radius:5px;
padding:5px;
width:max-content;
`

const Btn = styled.div`

`








const Search = styled.input`
width:200px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getDB_Data_lazy: (arg, gf) => dispatch(getDB_Data_lazy(arg, gf)),
    getDB_Data: (arg, gf) => dispatch(getDB_Data(arg, gf)),
    getBONUS_LOG: (arg, gf) => dispatch(getBONUS_LOG(arg, gf)),
  };
};


const mapStateToProps = (store) => {

  return {
    user: store.user.data,
    BONUS_LOG: store.page.BONUS_LOG,
    COMPANYS: store.page.COMPANYS,
    CLIENTS: store.page.CLIENTS,
    BONUS_LOG_TYPES: store.page.BONUS_LOG_TYPES,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index)

