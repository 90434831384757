
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp } from '../../../actions/PopUpActions';
import Table from '../../components/Table';
import {GlobalFunctions as GF} from '../../../GlobalFunctions'
import {  getSHOP_ITEMS} from '../../../actions/PageActions';

import POP_TovarChange from '../Settings/BayServices/POP_TovarChange';
import { Button, Tab, Tabs } from '@mui/material';
import { Search } from '../CUST/ClientBallItems';
import { Pallet, Styles } from '../../../constants/Constants';
import { Palette } from '@mui/icons-material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
const Rows = [
  
    {
      title:'Название',
      key:'TITLE',
    },
    {
      title:'Стоимость',
      key:'COAST',
    }
]


class Index extends React.Component {
    constructor(props) {
        super()
        this.state = {
            filter:null,
            ID_CATEGORY:1
        }
        this.checkBoxs=[]
        this.input=[]
    }



    getTitle(data){
        return [
          data.TITLE,
          <br/>,
          data.COMPANYS.length==5?
          <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>Все филиалы</small>   :
          data.COMPANYS.map(com=>
            <small style={{background:Pallet.Light.White}} className='me-2 mt-2 p-1 rounded'>{com.TITLE.replace('Rich Nails ','')}</small>  
          )
        ]
    }
    
    Delete(ID){
        return new Promise((resolve,reject)=>{
          let data = {
            operation:'deleteSome',
            Table:'SHOP_ITEMS',
            ID
          }
  
          
          
          GF.API_task(data).then(res=>{
            
            this.props.getSHOP_ITEMS()
            setTimeout(()=>resolve(),1)
            
          }).catch(()=>reject()) 
        })
        
      }


    render (){
        const {SHOP_ITEMS} = this.props
        const {filter} = this.state
        const {state,props} = this
        return (
           <MainView>
            <div>
            <Search style={{
              width:300,
            }} placeholder='Поиск' className='form-control float-start d-block me-2' onKeyUp={(e)=>this.setState({filter:e.target.value.length>0 ? e.target.value.toUpperCase() : null})}/>
           <Button size='small' className='me-2'  onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = true
              })
            }}>
                Выбрать все
            </Button>
           <Button size='small' className='me-2'  onClick={()=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                this.checkBoxs[key].checked = false
              })
            }}>
                Снять выделение
            </Button>
           <Button size='small' className='me-2'  onClick={()=>{
              Object.keys(this.checkBoxs).forEach(async (key,index)=>{
                if(this.checkBoxs[key].checked){
                  this.checkBoxs[key].checked=false
                  this.Delete(key)
                  delete(this.checkBoxs[key])
                }
              })
              
            }}>
                Удалить выбранные
            </Button>
           <Button size='small' className='me-2'  onClick={()=>{
           
               this.props.openPopUp(<POP_TovarChange tovarType={state.ID_CATEGORY==1?'товар':'услуга'}/>)
            }}>
                Добавить
            </Button>

            
            </div>

            
            
          
            
            <br/>

            <Tabs value={state.ID_CATEGORY} onChange={(e,value)=>{
              Object.keys(this.checkBoxs).forEach((key,index)=>{
                try{
                this.checkBoxs[key].checked = false
                }catch{}
              })
              this.setState({ID_CATEGORY:value})
            }} color='warning'>
              <Tab icon={<LocalOfferIcon fontSize='12'/>} iconPosition="start" color='warning' label="Товары" value={1}/>
              <Tab icon={<CleaningServicesIcon fontSize='12'/>} iconPosition="start" color='warning' label="Услуги" value={2} />
            </Tabs>
            <Table
                rows={Rows}
                canCheck={true}
                checkBoxs={this.checkBoxs}
                data={SHOP_ITEMS.filter(a=>a.ID_SHOP_CATEGORY ==this.state.ID_CATEGORY && a.ID>0 && (
                    filter==null || (
                    a.TITLE.toUpperCase().indexOf(filter)!=-1  )))}
                itemOnClick={({data,index})=>{
                    
                    this.props.openPopUp(<POP_TovarChange tovarType={state.ID_CATEGORY==1?'товар':'услуга'} ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>{this.getTitle(data)}</td>,
                    <td>{data.COAST} руб</td>,
                ]}/>
           </MainView>
        )
    }
}

const MainView = styled.div`

`
const Btn = styled.div`

`



const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getSHOP_ITEMS:(arg)=>dispatch(getSHOP_ITEMS(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        
        SHOP_ITEMS:store.page.SHOP_ITEMS
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index);

