import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../actions/PopUpActions'
import styled from 'styled-components'
import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import MainPage from '../pages/MainPage';
// import QuickOrder from '../pages/QuickOrder';
// import Analitics from '../pages/Analitics';
// import SendMails from '../pages/SendMails';
import { withRouter } from "react-router"
import Settings from '../pages/Settings';
import Clients from '../pages/Clients';
import Shop from '../pages/Shop';
import { up, down, between, only } from 'styled-breakpoints';
import ClientBallItems from '../pages/CUST/ClientBallItems';
import ClientRules from '../pages/CUST/ClientRules';
import Widgets from '../pages/Widgets';
import Reports from '../pages/Reports';
import ClientCompanys from '../pages/CUST/ClientCompanys';
import ClientOrders from '../pages/CUST/ClientOrders';
import { Pallet } from '../../constants/Constants';
import { Avatar, Chip, Menu, MenuItem } from '@mui/material';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { loadUserData_FromStorage } from '../../actions/UserAct';
import { GlobalFunctions } from '../../GlobalFunctions';



const AllMenu = {
    ADMN:[
        {
            title:'Конструктор виджета',
            url:'/main',
            view:<MainPage/>
        },
        {
            title:'Быстрый виджет',
            url:'/widget',
        },
        {
            title:'Аналитика',
            url:'/analitics',
        },
        {
            title:'Рассылки',
            url:'/sendmails',
        },
    ]
}

class LeftMenu extends React.Component {
    constructor(props) {
        super()
        this.state = {
           isOpen:false,
           anchorEl:false
        }
    }

    

    exitAk(){
        const { cookies } = this.props
        GlobalFunctions.deleteCookie('login')
        GlobalFunctions.deleteCookie('password')
        
        this.props.loadUserData_FromStorage()
    }
  

    render (){
        const {user} = this.props
        const {isOpen} = this.state
        const {state,props} = this
        const menu = AllMenu[user.ROLE]
        if(menu==null) return <div/>
        return ([
            <Burger isOpen={isOpen} onClick={()=>this.setState({isOpen:!isOpen})}>
                <div className='b1'/>
                <div className='b2'/>
                <div className='b3'/>
            </Burger>,
            <MasterView isOpen={isOpen}>
               
                {user.ID==null? null: 
                    <UserAvatar>
                        
                        
                        <Chip className='m-4' onClick={e=>this.setState({anchorEl:e.currentTarget})}
                            avatar={<Avatar color='warning' style={{
                                background:Pallet.Primary.Accent
                            }} src={!!user.IMAGES_URL && user.IMAGES_URL[0]}/>} 
                            label={user.LOGIN}
                            style={{
                                background:Pallet.Light.Accent
                            }}
                             />

                        {user.ROLE!='CUST'?null:
                        <Chip  label={GlobalFunctions.makeCoast(props.SELF_CUSTOMER.BONUS_COINS)+'Б'} color='success'/>}
                   
                    
                    
                        <Menu
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={()=>this.setState({anchorEl:null})}>
                            
                            <MenuItem size='small' onClick={()=>{
                                this.setState({anchorEl:null})
                                this.exitAk()
                            }}>
                                <ExitToAppRoundedIcon fontSize='small' style={{marginRight:10}}/>
                                Выход</MenuItem>
                        </Menu>
                     </UserAvatar>
                    
                }

                {menu.map(m => (
                    <Link to={m.url} onClick={()=>this.setState({isOpen:false})}>
                        <ListElem isSelected={this.props.location.pathname.indexOf(m.url)==0 || 
                            (m.url=='/main' && this.props.location.pathname=='/')}>{m.title}</ListElem>
                    </Link>
                ))}
            </MasterView>,

            <ContentView isOpen>
                <Switch>
                    <Route path='/widget'  component={() => {
                                window.location.href = 'https://sinails.quicksearch.futuris.shop/';
                                return null;
                            }}>
                        
                    </Route>
                    
                    <Route path='/analitics'  component={() => {
                                window.location.href = 'http://sinails.futuris.shop/d/tdFI7D94z/obshchaia-svodka-iskhodnye-dannye';
                                return null;
                            }}>
                        
                    </Route>
                    
                    <Route path='/sendmails'  component={() => {
                                window.location.href = 'https://services.futuris.shop/users/sign_in';
                                return null;
                            }}>
                        
                    </Route>
                    
                    {menu.map(m => (
                            <Route path={m.url}>
                                {m.view}
                            </Route>
                    ))}
                    
                    <Route path='/'>
                        <MainPage/>
                    </Route>
                    
                </Switch>
            </ContentView>
        ])
    }
}

const UserAvatar = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-right:20px;

${up('md')}{
    display:none;
}

& svg{
fill:${Pallet.Black};
}
`

const Burger = styled.div`
${up('md')}{
    display:none;
}
height: 26px;
width: 26px;
position: fixed;
top: 7px;
right: 20px;
z-index: 11;
cursor:pointer;
transition:.3s;
padding-top: 7px;

& div{
    transform-origin-x:50%;
    transition:.3s;
    background:${Pallet.Primary.Accent};
    height:2px;
    width:100%;
    margin-bottom:5px;
    border-radius:2px;
}

& div.b2{
    transition:all .3s, transform.3s ease .1s;
}

${props=>props.isOpen?`
    & div.b1{
        transform:scale(0.8,1) translateY(7px) rotate(34deg);
    }
    & div.b3{
        transform:scale(0.8,1) translateY(-7px) rotate(-34deg);
    }
    & div.b2{
        transform:scale(0,1);
    }
    & div:hover{
        margin-bottom:5px;
        
    }
    &:hover{
        transform:scale(1);
        padding-top: 7px;
    }
`:`
    &:hover div{
        margin-bottom:8px;
       
    }
    &:hover{
        transform:scale(1.1);
        padding-top: 4px;
    
    }
`}

`

const MasterView = styled.div`
width:230px;
height:calc(100vh - 50px);
background:${Pallet.Gray};
position:fixed;
top:50px;
left:0px;

a{
    color: rgb(151, 158, 167);
    text-decoration: none;
    font-variant: all-petite-caps;
    font-size: 18px;
    padding: 10px 20px;
    width: 100%;
    display: block;
    cursor: pointer;
    transition: .3s;
    padding-left: 30px;

}
a:hover{
    color:gray;
    background:rgba(0,0,0,0.01);
    
}
z-index:5;
border-top-right-radius: 5px;
${down('md')}{
    border-top-right-radius: 0px;
    overflow-y:auto;
    padding-bottom:150px;
    overflow-x:hidden;
    transition:.5s ease-in-out;
    width:100vw;
    right:0px;
    left:unset;
    ${props=>props.isOpen?`
        transform:translateX(0vw);
        box-shadow: 10px 0px 30px rgba(0,0,0,0.1);
    `:`
        transform:translateX(100vw);
        box-shadow: 10px 0px 30px rgba(0,0,0,0);
    `}
    
}
`

const ListElem = styled.div`
transition:.3s;
color:${Pallet.Primary.Accent};
font-family:'Cera Pro';
transition:.3s;
border-radius:5px;
padding-right: 10px;
line-height: 19px;
padding-bottom:5px;
padding-top:5px;
margin-right: -10px;
padding-left: 10px;
margin-left: -10px;
font-size:20px;
${props=>props.isSelected?`
 background:${Pallet.Primary.Accent};
 color:black;
`:`

`}
`

const ContentView = styled.div`
margin-left:230px;
width:calc(100vw - 230px);

height:calc(100vh - 50px);
overflow-y:auto;
margin-top:50px;
overflow-x:hidden;
padding:20px;
padding-top:0;

${down('md')}{
    padding-top:20;
    margin-left:0px;
    width:100vw;
    padding-bottom:200px;
    overflow-x:auto;
}
`


const mapDispatchToProps = (dispatch) => {
    return {
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        loadUserData_FromStorage: (arg)=>dispatch(loadUserData_FromStorage(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        SELF_CUSTOMER:store.page.SELF_CUSTOMER,
        user: store.user.data,
        page:store.page
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LeftMenu));

