import React from 'react';
import { connect } from 'react-redux';
import { openPopUp } from '../../actions/PopUpActions'
import { loadUserData_FromStorage } from '../../actions/UserAct'
import styled from 'styled-components'
import { GlobalFunctions } from '../../GlobalFunctions';
import { withCookies } from 'react-cookie';
import logo from '../../design/LongLogo.svg'
import { up, down, between, only } from 'styled-breakpoints';
import { Avatar, Button, Chip, FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';


import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { Pallet } from '../../constants/Constants';
import { setFilial } from '../../actions/PageActions';


class TopMenu extends React.Component {
    constructor(props) {
        super()
        this.state = {
           errorMSG:null,
           anchorEl:false
        }
        
    }

    

    componentDidMount() {
        
    }

    exitAk(){
        const { cookies } = this.props
        GlobalFunctions.deleteCookie('login')
        GlobalFunctions.deleteCookie('password')
        
        this.props.loadUserData_FromStorage()
    }


    render (){
        const {user} = this.props
        const {state,props} = this
        const {dispatch} = props
        return (
            <MasterView>
                
                <Logo src={logo}/>

                {user.ID==null? null:
                    <UserAvatar>
                        
                        <div style={{
                            
                            position:'absolute',
                            top:-15,
                            right:0,
                            display:'flex',
                            flexDirection:'flex-end',
                            alignItems:'center',
                            gap:20
                        }} className="m-4">
                            {user.ROLE!='CUST'?null:
                            <Chip  label={GlobalFunctions.makeCoast(props.SELF_CUSTOMER.BONUS_COINS)+'Б'} color='success'/>}

                            {user.ROLE!='ADMN' && user.ROLE!='MNGR'?null:
                            <FormControl fullWidth
                                style={{width:150}}
                                color='warning'
                                size='small'
                                variant='filled'>
                                <InputLabel>Филиал</InputLabel>
                                <Select
                                color='warning'
                                    size='small'
                                    value={props.selectedFilial}
                                    label="Филиал"
                                    onChange={(e)=>{
                                        dispatch(setFilial(e.target.value))
                                    }}>
                                        {props.COMPANYS.map(comp=>
                                            <MenuItem value={comp.ID}>{comp.TITLE.replace('Rich Nails ','')}</MenuItem>
                                        )}
                                </Select>
                            </FormControl>
                            }

                            <Chip style={{background:Pallet.Light.Accent}} onClick={e=>this.setState({anchorEl:e.currentTarget})}
                                avatar={<Avatar color='warning' style={{
                                    background:Pallet.Primary.Accent
                                }} src={!!user.IMAGES_URL && user.IMAGES_URL[0]}/>} 
                                label={user.LOGIN}
                                
                                />
                        </div>
                    
                    
                        <Menu
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={()=>this.setState({anchorEl:null})}>
                            
                            <MenuItem size='small' onClick={()=>{
                                this.setState({anchorEl:null})
                                this.exitAk()
                            }}>
                                <ExitToAppRoundedIcon fontSize='small' style={{marginRight:10}}/>
                                Выход</MenuItem>
                        </Menu>
                    
                </UserAvatar>}
            </MasterView>
        )
    }
}

const UserAvatar = styled.div`
${down('md')}{
    display:none;
}
& svg{
    fill:${Pallet.Black};
    }
`

const MasterView = styled.div`
height:50px;
width:100vw;
background:${Pallet.Black};
box-shadow:10px 10px 30px rgba(0,0,0,0.03);
position:fixed;
top:0px;
left:0px;
z-index:10;
`
const Logo = styled.img`
height: 20px;
margin-top: 15px;
margin-left: 30px;
float:left;

${down('md')}{
    height: 26px;
    margin-top: 9px;
    margin-left: 20px;
}
`



const mapDispatchToProps = (dispatch) => {
    return {
        dispatch:(arg)=>dispatch(arg),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        loadUserData_FromStorage: (arg)=>dispatch(loadUserData_FromStorage(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
        selectedFilial:store.page.selectedFilial,
        COMPANYS:store.page.COMPANYS,
        SELF_CUSTOMER:store.page.SELF_CUSTOMER,
        page:store.page,
        user:store.user.data
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(withCookies(TopMenu));

