import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { openPopUp,closePopUp } from '../../../../actions/PopUpActions';
import {  getDB_Data_lazy} from '../../../../actions/PageActions';
import { GlobalFunctions } from '../../../../GlobalFunctions';
import Input, { Label } from '../../../components/moduls/Input'
import { withRouter } from "react-router"
import { withCookies } from 'react-cookie'

import {BrowserRouter as Router,Switch, Route,Link} from "react-router-dom";
import Table from '../../../components/Table';

import { Avatar, Button, Chip } from '@mui/material';
import POP_User from './POP_User';



const Rows = [
  {
    title:'Логин',
    key:'LOGIN'
  },
  {
    title:'Роль',
    key:'ROLE'
  },
  {
    title:'Статус',
    key:'STATUS',
    width:'150px'
  },
]

class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          errorMSG:null,
          succes:false,
          filter:null,

          pop_filter:null,
          Data:[]
        }
        this.input={
          dataWindow:{},
          main:{}
        }
        this.checkBoxs={}
    }

    getRole(role){
      console.error(role)
      switch(role){
        case 'MNGR': return'Менеджер филиала'
        case 'DEVP': return'Разработчик сайта'
      }
    }

   

    render (){
      const {filter,Data} = this.state
      const {props,state} = this
      let filteredData = props.USERS
        return (
        <MainView>
          <Button onClick={()=>this.props.openPopUp(<POP_User/>)}>Новый пользователь</Button>
          <Table
                className='mt-3'
                rows={Rows}
                canCheck={false}
                data={filteredData}
                itemOnClick={({data,index})=>{
                  this.props.openPopUp(<POP_User ID_OBJECT={data.ID}/>)
                }}
                rowOnClick={({data,index})=>{
                    debugger
                }}
                renderItem={({data,index})=>[
                    <td>
                      <Chip size='small' avatar={<Avatar/>} label={data.LOGIN}/>
                    </td>,
                    <td>
                      <Chip size='small' color='secondary' label={this.getRole(data.ROLE)}/>
                    </td>,
                    <td><Chip size='small'color={
                      data.ID_STATUS == 2?'success':
                      data.ID_STATUS == 1?'warning':
                      data.ID_STATUS == 3?'info':'primary'
                    } label={data.STATUS_TITLE}/></td>
                ]}/>

          
        </MainView>
        )
    }
}

const MainView = styled.div`
margin-top:-10px;
`




const mapDispatchToProps = (dispatch) => {
    return {
        closePopUp:(arg)=>dispatch(closePopUp(arg)),
        openPopUp:(arg)=>dispatch(openPopUp(arg)),
        getDB_Data_lazy:(arg)=>dispatch(getDB_Data_lazy(arg)),
    };
  };


const mapStateToProps = (store) => {
   
    return {
      USERS:store.page.USERS,
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(Index)

